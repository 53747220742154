export const ROOT = '/';

export const POLICY_PAGE = '/policy';

export const CONTACT_PAGE = '/contact';

export const TERMS_PAGE = '/terms';

export const NEWS_PAGE = '/news';

export const POLICY_MOBILE_PAGE = '/policymobile';

export const TERMS_CONDITION_MOBILE_PAGE = '/termsconditionmobile';

export const ABOUT_PAGE='/about';

export const PAGE_NOT_FOUND = '/*';