import React from 'react';
import { createRoot } from 'react-dom/client';
import 'lazysizes';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './assets/css/custom.css';
import 'react-toastify/dist/ReactToastify.css';
import './i18n';
import App from './App';

import reportWebVitals from './reportWebVitals';
const root = document.getElementById('root');
if (root) {
  createRoot(root).render(<App />);
}

reportWebVitals();