import React from 'react';
import { ToastContainer } from 'react-toastify';
import Router from './routes/routes';
const NoInternetConnection = React.lazy(() => import('./pages/NoInternetConnection'));

function App() {
  return (
    <NoInternetConnection>
            <Router />
      <ToastContainer position='top-right' autoClose={2500} hideProgressBar={true} />
    </NoInternetConnection>
  );
}

export default App;
