import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const languageMapping = {
  gu: 'Gujarati',
  hi: 'Hindi',
  en: 'English',
  ta: 'Tamil',
  te: 'Telugu',
  bn: 'Bangla',
  mr: 'Marathi',
  kn: 'Kannada',
  pa: 'Punjabi',
  ml: 'Malayalam',
  sp: 'Spanish',
  pt: 'Portuguese',
  id: 'Indonesian',
  th: 'Thai',
  ma: 'Malay',
  vi: 'Vietnamese'
};
const supportedLanguages = Object.keys(languageMapping);

const loadedTranslations = {};

const loadTranslation = async (lang) => {
  return import(`./data/translations/${languageMapping[lang]}.json`);
};

const initializei18n = async () => {
  const translationPromises = supportedLanguages.map(async (lang) => {
    loadedTranslations[lang] = {
      translation: await loadTranslation(lang),
    };
  });

  await Promise.all(translationPromises);

  i18n
    .use(initReactI18next)
    .init({
      resources: loadedTranslations,
      lng: 'en', // default language
      keySeparator: false,
      fallbackLng: 'en',
      interpolation: {
        escapeValue: false,
      },
    });
};

initializei18n();

export default i18n;