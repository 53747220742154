import {
  POLICY_PAGE,
  PAGE_NOT_FOUND,
  ROOT,
  CONTACT_PAGE,
  TERMS_PAGE,
  NEWS_PAGE,
  POLICY_MOBILE_PAGE,
  TERMS_CONDITION_MOBILE_PAGE,
  ABOUT_PAGE
} from './constants';
import { lazy } from 'react';
const Home = lazy(() => import('../pages/Home'));
const PrivacyPolicy= lazy(() => import('../pages/PrivacyPolicy'));
const Contactus = lazy(() => import( '../pages/Contactus'));
const TermsConditions = lazy(() => import( '../pages/TermsConditions'));
const News = lazy(() => import('../pages/News'));
const PageNotFound = lazy(() => import( '../pages/PageNotFound'));
const About=lazy(() => import( '../pages/About'));

const pagesData = [
  {
    path: ROOT,
    component: Home,
    title: 'home'
  },
  {
    path: POLICY_PAGE,
    component: PrivacyPolicy,
    title: 'privacypolicy'
  },
  {
    path: CONTACT_PAGE,
    component: Contactus,
    title: 'contactus'
  },
  {
    path: TERMS_PAGE,
    component: TermsConditions,
    title: 'termsconditions'
  },
  {
    path: NEWS_PAGE,
    component: News,
    title: 'news'
  },
  {
    path: POLICY_MOBILE_PAGE,
    component: PrivacyPolicy,
    title: 'privacypolicy'
  },
  {
    path: TERMS_CONDITION_MOBILE_PAGE,
    component: TermsConditions,
    title: 'termsconditions'
  },
  {
    path: PAGE_NOT_FOUND,
    component: PageNotFound,
    title: 'pagenotfound'
  },
  {
    path: ABOUT_PAGE,
    component: About,
    title: 'about'
  }
]

export default pagesData;