import React, { Suspense } from 'react';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import pagesData from './pagesData';

const Router = () => {
    const pageRoutes = pagesData.map(({ path, title, component: PageComponent }) => {
        return <Route key={title} path={`/${path}`} element={<PageComponent />} />;
    });

    return <BrowserRouter>
        <Suspense>
            <Routes>{pageRoutes}</Routes>
        </Suspense>
    </BrowserRouter>
};

export default Router;